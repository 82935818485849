import './Admin.scss';
import {useTranslation} from "react-i18next";
import React, { useState } from 'react';
import Button from "../../components_global/button/Button";

const Admin = () => {
  const {t} = useTranslation();
  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(false);

  return(
    <div className="mH50 admin">
      <div className="mT50 fontSize16 bold">{t("AnimationWaiting")}</div>
      <div className="mT10 gap20 anim" onClick={()=>setIsInfoVisible(!isInfoVisible)}/>
      {isInfoVisible && <div className="mT50">
        <div className="fontSize14 bold mT10">{t("MAJSurname")}</div>
        <div className="fontSize14 bold mT10">{t("MAJName")}</div>
        <div className="fontSize14 bold mT10">{t("Description")}</div>
        <div className="fontSize14 bold mT10">{t("Aperçu")}</div>
        <div className="mT10 gap20">
          <div className="apercu"/>
        </div>
      </div>}
      <div className="flexHorizontal gap40 mT50">
        <Button text={t("NotApproval")} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'} onClick={()=>console.log('Goodbye')}></Button>
        <Button text={t("Approval")} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'} onClick={()=>console.log('Hello')}></Button>
      </div>    
    </div>
  );
}

export default Admin;