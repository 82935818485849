import axios from "axios";

export const uploadImage = (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${process.env.REACT_APP_URL_API}/s3/upload/image`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};

export const uploadVideo = (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${process.env.REACT_APP_URL_API}/s3/upload/video`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};


export const uploadObject = (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${process.env.REACT_APP_URL_API}/s3/upload/object`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};

export const uploadMiniGame = (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${process.env.REACT_APP_URL_API}/s3/upload/mini-game`, formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};

export const custom = (object: any) => {
  return axios.post(`${process.env.REACT_APP_URL_API}/custom`,
    object
  ,{
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};

export const getobject = () => {
  return axios.get(`${process.env.REACT_APP_URL_API}/file/objects`
  ).then((response) => {
    return response.data
  })
}