import React from "react";
import {FC} from "react";

interface LogoLifotoProps {
  size: number
  className?: string
  color: string
}

const LogoLifoto: FC<LogoLifotoProps> = ({size, className, color}) => {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.868 24.7H24.468V28H14.593V10.45H18.868V24.7Z" fill={color}/>
      <path d="M25.4375 7.55966H31.2188V13.1193" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.5625 32.0222H5.78125V26.4625" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2188 26.4625V32.0222H25.4375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.78125 13.1193V7.55966H11.5625" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export default LogoLifoto;