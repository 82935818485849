import './Footer.scss';
import React from 'react';
import LifotoLongLogo from "../../assets/icons/LifotoLongLogo";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import i18n from '../../i18n/i180';

const Footer = () => {
  const {t} = useTranslation();

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return(
    <footer className="backColorPrimary flexVerticalBetween pV20 borderBox fontSize14">
      <div className="flexHorizontalEnd gap5 width100">
        <div className="pointer language" onClick={() => handleLanguageChange('fr')}>FR</div>
        <div>-</div>
        <div className="pointer pR20 language" onClick={() => handleLanguageChange('en')}>ENG</div>
      </div>
      <div className="flexHorizontalEvenly width100 fontWeight300">
        <LifotoLongLogo color="white" size={300} className="logo"/>
        <div className="flexHorizontal gap40 height100">
          <div className="lineVertical"></div>
          <div className="info flexVerticalStart gap20 pointer">
            <NavLink to="/team">{t("ourTeam")}</NavLink>
            <NavLink to="/timeline">{t("timeline")}</NavLink>
          </div>
        </div>
        <div className="flexHorizontal gap40 height100">
          <div className="lineVertical"></div>
          <div className="flexVerticalStart gap20">
            <div>{t("contact")}</div>
            <a href="mailto:theo.miquet@epitech.eu">theo.miquet@epitech.eu</a>
            <a href="mailto:theo.nol-ygonnin@epitech.eu">theo.nol-ygonnin@epitech.eu</a>
            <a href="mailto:lifoto@epitechfr.onmicrosoft.com">lifoto@epitechfr.onmicrosoft.com</a>
          </div>
        </div>
      </div>
      <div className="legale flexHorizontal gap5 pointer">
        <NavLink to="/legal-notices">{t("legalNotices")}</NavLink>
        <div>-</div>
        <NavLink to="/condition-general-use">{t("CGU")}</NavLink>
        <div>-</div>
        <NavLink to="/condition-general-sale">{t("CGV")}</NavLink>
        <div>-</div>
        <NavLink to="/privacy-charter">{t("privacyCharter")}</NavLink>
      </div>
      <div>© Copyright Lifoto</div>
    </footer>
  );
}

export default Footer;