import React, {useEffect, useState} from 'react';
import Button from "../../components_global/button/Button";
import './Profil.scss';
import {useTranslation} from "react-i18next";
import {TextField} from '@mui/material';
import {isBirthdateValid, isEmailValid, isPhoneNumber, isText} from "../../utils/regex";
import {AuthContextType} from "../../context/AuthContext";
import {useAuth} from "../app/App";
import {formatDate} from "../../utils/formatting";
import { User } from '../../type/type';
import { useToast } from '../../context/ToastContext';

const Profil = () => {
  const {t} = useTranslation();
  const auth: AuthContextType | null = useAuth();
  const { showToast } = useToast();
  const [name, setName] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const saveInfoUser = () => {
    const updatedInfo: User = {};

    if (email) {
      updatedInfo.email = email;
    }
    if (name) {
      updatedInfo.lastname = name;
    }
    if (firstname) {
      updatedInfo.firstname = firstname;
    }
    if (birthdate) {
      updatedInfo.birthday = birthdate;
    }
    if (phone) {
      updatedInfo.phone = phone;
    }

    auth?.updateUser(updatedInfo).then((res) => {
      const user: User = res as User;
      setEdit(false);
      setUser(user);
      showToast({
        message: t("saveUserSuccess"),
        severity: 'success',
      });
    }).catch((error) => {
      console.error(error)
      showToast({
        message: t('errorUpdateUser'),
        severity: 'error',
      });
    });
  }

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    auth?.getUser().then((res) => {
      setUser(res.data);
    }).catch((error) => {
        console.error(error)
    });
  }

  return (
    <div className="profil borderBox">
      <div className="flexVerticalEnd">
        <Button onClick={() => setEdit(!edit)} text={t("edit")} color='white' background={'#5C72D8'}
                backgroundHover={'#6419B9'}/>
      </div>
      <div className="flexHorizontalStart gap20 alignCenter mB50">
        <div className="photo"/>
        <div>{user?.lastname +' '+ user?.firstname}</div>
      </div>
      <div className="flexVerticalStart gap20 mB50">
        <div className="flexSpaceBetween alignCenter width100">
          <div>{t('name')}</div>
          {edit ?
            <TextField
              hiddenLabel
              className="width80"
              id="filled-hidden-label-small"
              defaultValue={user?.lastname ?? ""}
              variant="filled"
              size="small"
              type="text"
              name="name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              error={!isText(name)}
              helperText={!isText(name) ? t("invalidText") : ""}
            />
            : <div className="width80">{user?.lastname}</div>
          }
        </div>
        <div className="flexSpaceBetween alignCenter width100">
          <div>{t('firstName')}</div>
          {edit ?
            <TextField
              hiddenLabel
              className="width80"
              id="filled-hidden-label-small"
              defaultValue={user?.firstname ?? ""}
              variant="filled"
              size="small"
              type="text"
              name="firstname"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstname(event.target.value);
              }}
              error={!isText(firstname)}
              helperText={!isText(firstname) ? t("invalidText") : ""}
            /> : <div className="width80">{user?.firstname}</div>
          }
        </div>
        <div className="flexSpaceBetween alignCenter width100">
          <div>{t('email')}</div>
          {edit ?
            <TextField
              hiddenLabel
              className="width80"
              id="filled-hidden-label-small"
              defaultValue={user?.email ?? ""}
              variant="filled"
              size="small"
              type="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              error={!isEmailValid(email) && email !== ''}
              helperText={!isEmailValid(email) && email !== '' ? t("invalidEmail") : ""}
            /> : <div className="width80">{user?.email}</div>
          }
        </div>
        <div className="flexSpaceBetween alignCenter width100">
          <div>{t('birthdate')}</div>
          {edit ?
            <TextField
              hiddenLabel
              className="width80"
              id="filled-hidden-label-small"
              defaultValue={user?.birthday ? formatDate(user?.birthday) : ""}
              variant="filled"
              size="small"
              type="text"
              name="datetime"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBirthdate(event.target.value);
              }}
              error={!isBirthdateValid(birthdate) && birthdate !== ''}
              helperText={!isBirthdateValid(birthdate) && birthdate !== '' ? t("invalidFormatBirthday") : ""}
            /> : <div className="width80">{user?.birthday ? formatDate(user?.birthday) : ''}</div>
          }
        </div>
        <div className="flexSpaceBetween alignCenter width100">
          <div>{t('phone')}</div>
          {edit ?
            <TextField
              hiddenLabel
              className="width80"
              id="filled-hidden-label-small"
              defaultValue={user?.phone ?? ""}
              variant="filled"
              size="small"
              type="text"
              name="phone"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(event.target.value);
              }}
              error={!isPhoneNumber(phone) && phone !== ''}
              helperText={!isPhoneNumber(phone) && phone !== '' ? t("invalidPhone") : ""}
            /> : <div className="width80">{user?.phone}</div>
          }
        </div>
      </div>
      {edit &&
        <div className="flexHorizontalEnd alignCenter width100 mB16">
          <Button onClick={() => saveInfoUser()} text={t('save')} color='white' background={'#5C72D8'}
                  backgroundHover={'#6419B9'}/>
        </div>}
      <div>{t('addressSave')}</div>
    </div>
  );
}

export default Profil;