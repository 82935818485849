import React, {useContext, useEffect} from "react";
import { useTranslation } from "react-i18next";
import {StepperContext} from "../../context/StepperContext";
import Login from "../login/Login";

const ValidateCustom = () => {
  const {t} = useTranslation();
  const token = sessionStorage.getItem("token_access") ?? '';
  const stepper = useContext(StepperContext);

  useEffect(() => {
    if(stepper) {
      if(sessionStorage.getItem("token_access") && sessionStorage.getItem("token_access") !== '') {
        stepper.setNextStep(false);
      } else {
        stepper.setNextStep(true);
      }
    }
  }, [sessionStorage.getItem("token_access")]);

  return(
    <div>
      {token !== '' ?
        <div className="fontSize18 uppercase fontWeight600 textAlignCenter textColorPrimary mV50">{t('validateCustom')}</div>
        :
        <div>
          <div className="fontSize18 uppercase fontWeight600 textAlignCenter textColorPrimary mV50">{t('loginCustom')}</div>
          <Login/>
        </div>
      }
    </div>
  );
}

export default ValidateCustom;