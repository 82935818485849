import './Menu.scss';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {myTranslation} from '../../i18n/i180';
import Lifoto from "../../assets/icons/lifoto";
import { useNavigate } from 'react-router-dom';
import {AuthContextType} from "../../context/AuthContext";
import {useAuth} from "../app/App";
import Button from "../../components_global/button/Button";

const Menu = () => {
  const {t} = useTranslation();
  const [lineWidth, setLineWidth] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenBurger, setIsOpenBurger] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const navigate = useNavigate();
  const auth: AuthContextType | null = useAuth();

  useEffect(() => {
    const homeElement = document.getElementById("home");
    if (homeElement) {
      const rect = homeElement.getBoundingClientRect();
      const line = document.getElementById("line");
      if (line) {
        // line.style.left = rect.left + 'px';
        line.style.transform = 'translate3d(' + (rect.left - 8) + 'px,0,0)';
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    handleResize(); // Appeler immédiatement pour initialiser la valeur

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile])


  const redirect = (link: string, targetElement: HTMLDivElement | null, shouldNavigate: boolean = true) => {
    document.body.classList.remove('overflowHidden');

    if (shouldNavigate) {
      navigate(link);
    }
  }

  const navigateSetting = (link: string) => {
    navigate(link);
    setIsOpenSettings(false);
  }

  const changeWidthLine = () => {
    const positionLine = document.getElementById("line");
    if (positionLine && lineWidth) {
      positionLine.style.width = `${lineWidth}px`;
    }
  }
  const openParams = () => {
    setIsOpenSettings(!isOpenSettings);
  }

  const openBurger = () => {
    setIsOpenBurger(!isOpenBurger)
    if(!isOpenBurger) {
      document.body.classList.add('overflowHidden');
    } else {
      document.body.classList.remove('overflowHidden');
    }
  }

  const drawNavLine = useCallback(
    () => {
      const targets: { [key: string]: HTMLDivElement } = {
        '/': document.getElementById('home') as HTMLDivElement,
        '/shop': document.getElementById('shop') as HTMLDivElement,
        '/community': document.getElementById('community') as HTMLDivElement,
      }

      const targetElement = targets[document.location.pathname]
      const positionLine = document.getElementById("line");

      if (positionLine && targetElement) {
        const rect = targetElement.getBoundingClientRect();

        setLineWidth((oldWidth) => {
          // Utilisez les coordonnées pour ajuster la position de l'élément "line"
          if (positionLine.style) {
            positionLine.style.visibility = 'visible'
            positionLine.style.transform = 'translate3d(' + (rect.left - 8) + 'px,0,0)';
            if (oldWidth) {
              positionLine.style.transition = 'all 500ms';
            }
          }
          return targetElement.offsetWidth
        })

        setIsOpenSettings(false);
        setIsOpenBurger(false)
      }

      setIsOpenBurger(!isOpenBurger);
    },
    [document.location.pathname],
  )

  useEffect(() => {
    setTimeout(drawNavLine, 50)
    }, [document.location.pathname, isMobile],
    )


  return (
    <nav className="zindex1 backColorPrimary flexHorizontal flexSpaceBetween texte textColorWhite pSide8 borderBox">
      {isMobile ?
        <>
          <span className="material-symbols-outlined fontSizeIcon pointer" onClick={() => openBurger()}>menu</span>
          {isOpenBurger && (
            <div className={`zindex1 absolute backColorPrimary fullWidthHeight initTopLeft flexVerticalStartCenter gap40 pT50 borderBox menu menuMobile`}>
              <Lifoto size={60} color="white" className="pointer"/>
              <div id="home" onClick={(event) => redirect("/home", event.currentTarget)}>{t('menu.home')}</div>
              <div id="shop" onClick={(event) => redirect('/shop', event.currentTarget)}>{t("menu.shop", 'lowercase')}</div>
              <div id="community" onClick={(event) => redirect('/community', event.currentTarget)}>{t("menu.community")}</div>
              <div id="profil" onClick={(event) => redirect('/profil', event.currentTarget)}>{t("menu.profil")}</div>
              <div id="commands" onClick={(event) => redirect('/commands', event.currentTarget)}>{t("menu.commands")}</div>
              <div id="params" onClick={(event) => redirect('/settings', event.currentTarget)}>{t("menu.setting")}</div>
            </div>
          )}
        </>
        :
        <>
          <div className="flexHorizontal gap60 menu">
            <Lifoto size={60} color="white" className="pointer"/>
            <div id="home" onClick={(event) => redirect("/home", event.currentTarget)}>{myTranslation('menu.home', 1, {capitalize: false})}</div>
            <div id="shop" onClick={(event) => redirect('/shop', event.currentTarget)}>{t("menu.shop", 'lowercase')}</div>
            <div id="community" onClick={(event) => redirect('/community', event.currentTarget)}>{t("menu.community")}</div>
          </div>
          { auth?.isConnected ?
            <span className="material-symbols-outlined fontSizeIcon pointer" onClick={() => openParams()}>account_circle</span>
            :
            <Button onClick={() => navigate('/login')} text={t('signIn')}
                    background={'#6419B9'} backgroundHover={'#2c0c67'} color="white"/>
          }

          <div id="line"
               className={`absolute line backColorWhite`}
               ref={changeWidthLine}/>

          {isOpenSettings && auth?.isConnected && (
            <div className="fixed menuSetting gap20 flexVertical pV20 textColorBlack">
              <div onClick={(event) => navigateSetting('/settings')}>{t("menu.profil")}</div>
              <div onClick={(event) => navigateSetting('/commands')}>{t("menu.commands")}</div>
              <div onClick={(event) => navigateSetting('/settings')}>{t("menu.setting")}</div>
            </div>
          )}
        </>
      }
    </nav>
  );
}

export default Menu;