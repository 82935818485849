import './Button.scss';
import React, {FC, useState} from 'react';

interface ButtonProps {
  text: string
  className?: string
  color: string
  background: string
  backgroundHover: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disable?: boolean
}

const Button:FC<ButtonProps> = ({text, className, color, background, backgroundHover, onClick, disable = false}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    background: isHovered ? backgroundHover : background,
    color: color,
  };

  return(
    <button className={`${className} fontFamily`} style={buttonStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
            disabled={disable}
    >{text}</button>
  );
}

export default Button;