import React, { FC, useEffect, useState } from "react";
import { ReactElement } from "react";
import axios, { AxiosResponse } from "axios";
import { User } from "../type/type";
import useRefreshToken from "../hooks/useRefreshToken";

export let AuthContext = React.createContext<AuthContextType | null>(null)

export interface AuthContextType {
  isConnected: boolean,
  register: (email:string, password:string, confirmPassword:string, lastName:string, firstName:string, role:string,  birthday:string, phone:string) => Promise<AxiosResponse>
  signIn: (email: string, password: string) => Promise<AxiosResponse>,
  logout: () => void,
  getUser: () => Promise<AxiosResponse>,
  updateUser: (userData: User) => Promise<AxiosResponse>
}

interface AuthProviderProps {
  children: ReactElement | ReactElement[]
}

export const AuthProvider:FC<AuthProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [jwt, setJwt] = useState<string>('');
  const { validateToken, refresh } = useRefreshToken();

  useEffect(refresh, []);

  useEffect(() => {
    setIsConnected(validateToken);
    if (sessionStorage.getItem("token_access")) {
      const token = sessionStorage.getItem("token_access");
      if (token !== null) {
        setJwt(token);
      }
    }
  }, [validateToken]);

  const signIn = (email:string, password:string) => {
    return axios.post(`${process.env.REACT_APP_URL_API}/user/sign-in`, {
      "email": email,
      "password": password
    }).then((response) => {
      sessionStorage.setItem("token_access", response.data);
      setIsConnected(true);
      setJwt(response.data);
      return response;

    }).catch((error) => {
      setIsConnected(false);
      return error;
    })
  }

  const register = (email:string, password:string, confirmPassword:string, lastName:string, firstName:string, role:string, birthday:string, phone:string) => {
    return axios.post(`${process.env.REACT_APP_URL_API}/user/sign-up`, {
      "email": email,
      "password": password,
      "lastname": lastName,
      "firstname": firstName,
      "role": role,
      "birthday": birthday,
      "phone": phone
    }).then((response) => {
      if (response.data) {
        setIsConnected(true);
        sessionStorage.setItem("token_access", response.data);
      }
      return response
    })
  };

  const logout = () => {
    sessionStorage.removeItem("token_access");
    setIsConnected(false);
  }


  const getUser = () => {
    return axios.get(`${process.env.REACT_APP_URL_API}/user`,{
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }).then((response) => {
      return response;
    }).catch((error) => {
      return error;
    })
  }

  const updateUser = (userData: User) => {
    return axios.patch(`${process.env.REACT_APP_URL_API}/user`, userData, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }).then((response) => {
      const responseData = response.data;
      const user: User = {
        email: responseData.email,
        lastname: responseData.lastname,
        firstname: responseData.firstname,
        birthday: responseData.birthday,
        phone: responseData.phone
      } as User
      return user;
    }).catch((error) => {
      return error;
    })
  }

  const value = { isConnected, setIsConnected, register, signIn, logout, getUser, updateUser};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
