
export const formatSecondeTime = (seconds: number):string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const formatDate = (date: string):string => {
  const newdate = new Date(date);
  const day = newdate.getDate().toString().padStart(2, '0');
  const month = (newdate.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont indexés à partir de 0
  const year = newdate.getFullYear();
  return `${day}/${month}/${year}`;
}

export const formatDateApi = (date: string):string => { //jj/mm/aaaa -> aaaa-mm-jj
  const parts = date.split('/');

  const dateObject = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Ajouter un zéro devant si nécessaire
  const day = String(dateObject.getDate()).padStart(2, '0'); // Ajouter un zéro devant si nécessaire

  // Formater la date au format "aaaa-mm-jj"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}