import React, {useContext, useState} from 'react';
import './Login.scss'
import {useTranslation} from "react-i18next";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from "../../components_global/button/Button";
import {AuthContext, AuthContextType} from "../../context/AuthContext";
import { useAuth } from '../app/App';
import {useLocation, useNavigate} from "react-router-dom";
import {isBirthdateValid} from "../../utils/regex";
import {formatDateApi} from "../../utils/formatting";

const Login = () => {
  const {t} = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [createAccount, setCreateAccount] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPwd, setConfirmPwd] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [role, setRole] = useState<string>('user');
  const [textError, setTextError] = useState<string>('');
  const [visibilityTextError, setVisibilityTextError] = useState<Boolean>(false);
  const [birthdateError, setBirthdateError] = useState<boolean>(false);

  const auth: AuthContextType | null = useAuth();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  const apiLogin = () => {
    const dateBirthdate = formatDateApi(birthdate);
    if(createAccount) {
      auth?.register(email, password, confirmPwd, lastname, firstname, role, dateBirthdate, phone)
        .then((res) => {
          if(res.status === 201) {
            if(location.pathname !== "/shop") {
              navigate('/')
            }
          } else if (res.status === 404) {
            setTextError(t('invalidSignIn').toString());
            setVisibilityTextError(true);
          } else {
            setTextError(t('errorServer').toString());
            setVisibilityTextError(true);
          }
        }).catch((error) => {
          setTextError(t('errorServer').toString());
          setVisibilityTextError(true);
      });
    } else {
      auth?.signIn(email, password)
        .then((res) => {
          if(res.status === 201) { //res.status <= 200 && res.status >= 300
            if(location.pathname !== "/shop") {
              navigate('/')
            }
          } else if (res.status === 404) {
            setTextError(t('invalidLogin').toString());
            setVisibilityTextError(true);
          } else {
            setTextError(t('errorServer').toString());
            setVisibilityTextError(true);
          }
      }).catch((error) => {
        setTextError(t('errorServer').toString());
        setVisibilityTextError(true);
      });
    }
  }

  const displayBirthdateValidation = (value: string) => {
    setBirthdate(value);
    setBirthdateError(!isBirthdateValid(value))
  }

  return (
    <div className='login flexVertical'>
      <div className={`flexVerticalStartCenter backgroundLogin pV40 borderBox gap40 ${createAccount ? 'width70': 'width40' }`}>
        <div className="fontWeight600 fontSize20">{createAccount ? t('createAccount') : t('connexion')}</div>
        {!createAccount ?
          <div className="width80 gap40 flexVertical relative zindex0">
            <TextField id="outlined-basic" label={t("email")} variant="outlined"
                       value={email}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setEmail(event.target.value);
                       }}
            />
            <FormControl sx={{width: '25ch'}} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("password")}
              />
            </FormControl>
            { !createAccount && (
              <div className="absolute forgotPwd fontSize14">{t('forgotPwd')}</div>
            )}
          </div>
          :
          <div className="grid zindex0">
            <TextField id="outlined-basic" label={t("lastname")} variant="outlined"
                       value={lastname}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setLastname(event.target.value);
                       }}/>
            <TextField id="outlined-basic" label={t("firstname")} variant="outlined"
                       value={firstname}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setFirstname(event.target.value);
                       }}/>
            <TextField id="outlined-basic" label={t("email")} variant="outlined"
                       value={email}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setEmail(event.target.value);
                       }}/>
            <FormControl sx={{width: '25ch'}} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={confirmPwd}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setConfirmPwd(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("password")}
              />
            </FormControl>
            <TextField id="outlined-basic" label={t("phone")} variant="outlined"
                       value={phone}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setPhone(event.target.value);
                       }}/>
            <FormControl sx={{width: '25ch'}} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">{t("confirmPwd")}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("confirmPwd")}
              />
            </FormControl>
            <TextField id="outlined-basic" label={t("birthdate")} variant="outlined"
                       error={birthdateError}
                       value={birthdate}
                       helperText={birthdateError && t("invalidFormatBirthday")}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         displayBirthdateValidation(event.target.value)
                       }}/>
          </div>
        }
        <div className="gap10 flexVertical">
          <Button onClick={apiLogin} text={createAccount ? t('createAccount') : t('signIn')}
                  background={'#5C72D8'} backgroundHover={'#6419B9'} color="white"/>
          <div className="fontSize14 pointer" onClick={() => setCreateAccount(!createAccount)}>
            {createAccount ? t('signIn') : t('createAccount')}
          </div>
        </div>
        {visibilityTextError && (
          <div className="textColorRed fontSize14 mH50 flexHorizontal textAlignCenter">{textError}</div>
        )}
      </div>
    </div>
  );
}

export default Login;