import React, {useContext} from "react";
import {DialogContext} from "../../context/DialogContext";
import './dialog.scss';

const MoreFilters = () => {
  const dialog = useContext(DialogContext);

  return(
    <div className="backColorWhite moreFilters">
      <span className="material-symbols-outlined pointer" onClick={() => dialog?.closeDialog()}>close</span>
      filtre
    </div>
  );
}

export default MoreFilters;