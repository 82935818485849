import React, {createContext, Dispatch, FC, ReactElement, SetStateAction, useState} from "react";
import { Custom } from "../type/type";

export interface StepperContextProps {
  name: string,
  step: number,
  nextStep: boolean,
  object: Custom,
  setName: Dispatch<SetStateAction<string>>;
  setStep: Dispatch<SetStateAction<number>>;
  setObject: (object: Custom) => void;
  setNextStep: ( nextStep: boolean) => void;
}

interface StepperProviderProps {
  children: ReactElement | ReactElement[]
}

export let StepperContext = createContext<StepperContextProps | null>(null);

export const StepperProvider:FC<StepperProviderProps> = ({ children }) => {
  const [name, setName] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [nextStep, setNextStep] = useState<boolean>(true);
  const [object, setObject] = useState<Custom>({type: ''});


  const value: StepperContextProps = {
    name,
    step,
    object,
    nextStep,
    setName,
    setStep,
    setObject,
    setNextStep
  };

  return (
    <StepperContext.Provider value={value}>
      {children}
    </StepperContext.Provider>
  );
}