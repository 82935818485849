import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from "../views/home/Home";
import Community from "../views/community/Community";
import Shop from '../views/shop/Shop';
import Settings from "../views/settings/Settings";
import Timeline from "../views/timeline/Timeline";
import Team from '../views/team/Team';
import ConditionGeneral from "../views/condition_general/ConditionGeneral";
import {LegalNotices} from "../assets/text/LegalNotices";
import {PrivacyCharter} from "../assets/text/PrivacyCharter";
import {CGU} from '../assets/text/CGU';
import {CGV} from '../assets/text/CGV';
import Login from '../components/login/Login';
import Beta from '../views/beta/Beta';
import Admin from '../views/admin/Admin';
import Creator from '../views/Creator/Creator';
import {useAuth} from "../components/app/App";
import PrivateRoutes from './PrivateRoute';

const Router = () => {
  const auth = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Beta/>}/>
      <Route path="/home" element={<Home/>}/>
      <Route path="/shop" element={<Shop/>}/>
      <Route path="/community" element={<Community/>}/>
      <Route path="/timeline" element={<Timeline/>}/>
      <Route path="/team" element={<Team/>}/>
      <Route path="/legal-notices" element={<ConditionGeneral text={LegalNotices.text}/>}/>
      <Route path="/condition-general-use" element={<ConditionGeneral text={CGU.text}/>}/>
      <Route path="/condition-general-sale" element={<ConditionGeneral text={CGV.text}/>}/>
      <Route path="/privacy-charter" element={<ConditionGeneral text={PrivacyCharter.text}/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route element={<PrivateRoutes isAllowed={auth?.isConnected ?? false} />}>
        <Route path="/settings" element={<Settings defaultDisplay='profil'/>}/>
        <Route path="/commands" element={<Settings defaultDisplay='commands'/>}/>
        <Route path="/coordinate" element={<Settings defaultDisplay='coordinate'/>}/>
        <Route path="/crush" element={<Settings defaultDisplay='crush'/>}/>
        <Route path="/admin" element={<Admin/>}/>
        <Route path="/creator" element={<Creator/>}/>
      </Route>
    </Routes>
  );
};

export default Router;