import React, {FC} from 'react';
import './CardTeam.scss'

interface InterfaceCard {
  name: string,
  role: string,
  picture: string
}

const CardTeam:FC<InterfaceCard> = ({name, role, picture}) => {

  return (
    <div className="CardTeam radius10 borderBox flexVertical flexSpaceBetween">
      <img src={picture} alt="Image de profil"/>
      <div>
        <div className="textAlignCenter">{name}</div>
        <div className="textAlignCenter fontSize16">{role}</div>
      </div>
    </div>
  );
}

export default CardTeam;