import React from "react";
import {FC} from "react";
import './Icon.scss';

interface LoveProps {
  size: number
  className?: string
  color?: string
}

const Love: FC<LoveProps> = ({size, className, color}) => {
  return (
    <svg className={className} width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.18705 13.2485L3.18683 13.2482C2.3391 12.1075 1.7797 11.1015 1.47193 10.2267C1.15736 9.33258 1.00085 8.42276 1 7.49273C1.0001 5.60751 1.62117 4.08459 2.85288 2.85288C4.08469 1.62107 5.60776 1 7.49319 1C8.52773 1 9.50695 1.21771 10.4412 1.65501C11.377 2.09304 12.1818 2.70836 12.862 3.50868L13.624 4.40508L14.3859 3.50868C15.0662 2.70836 15.871 2.09304 16.8068 1.65501C17.741 1.21771 18.7202 1 19.7548 1C21.6402 1 23.1633 1.62107 24.3951 2.85288C25.6269 4.08469 26.248 5.60775 26.248 7.49319C26.248 8.42275 26.0916 9.33215 25.7763 10.2259C25.4675 11.1014 24.9078 12.1077 24.0608 13.2486C23.2138 14.3892 22.0577 15.6971 20.5804 17.1744C19.0984 18.6563 17.2165 20.4259 14.9314 22.4848C14.9309 22.4852 14.9305 22.4856 14.9301 22.486L13.624 23.6569L12.3179 22.486C12.3174 22.4856 12.317 22.4852 12.3166 22.4848C10.0314 20.4259 8.14955 18.6563 6.6676 17.1744C5.19026 15.697 4.03406 14.3891 3.18705 13.2485Z"
            fill="#423F47" fillOpacity="0.2" stroke="#6419B9" strokeWidth="2"/>
    </svg>
  );
}

export default Love;