import React from 'react';
import './SearchBar.scss';
import {useTranslation} from "react-i18next";

const SearchBar = () => {
  const {t} = useTranslation();

  const search = () => {
  }
  return(
    <div className="search">
      <input type="text" placeholder={t('search') ?? 'error'} onChange={() => search()}/>
      <span className="material-symbols-outlined textColorPrimary">search</span>
    </div>
  );
}

export default SearchBar;