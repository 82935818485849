import './StepperShop.scss'
import Button from "../../components_global/button/Button";
import ConfigPhoto from "../config_photo/ConfigPhoto";
import {useTranslation} from "react-i18next";
import {StepperContext} from "../../context/StepperContext";
import {FC, useContext, useEffect} from "react";
import React from "react";
import ChoiceImage from "../choice_image/ChoiceImage";
import ChoiceMod from "../Choice_mod/ChoiceMod";
import ListCreation from '../list_creation/ListCreation';
import ValidateCustom from '../validate_custom/validateCustom';
import {custom, uploadImage, uploadMiniGame, uploadObject, uploadVideo} from "../../services/customAPi";
import {useToast} from "../../context/ToastContext";
import {Custom} from "../../type/type";

interface StepperShopProps {
  setOpenStatus: (newOpenStatus: boolean) => void;
}

const StepperShop:FC<StepperShopProps> = ({ setOpenStatus }) => {
  const {t} = useTranslation();
  const stepper = useContext(StepperContext);
  const { showToast } = useToast();
  const listModVideo = [0,1,2,3,5, 6];
  const listModObjet = [0,1,2,4,5];
  const listModMiniGame = [0,1,2,4,5];

  const changeStep = () => {
    let list = [0,1,2,3,4,5];
    if(stepper && stepper?.object.type !== '') {
      switch (stepper.object.type) {
        case 'video': list = listModVideo; break;
        case 'image': list = listModObjet; break;
        case 'mini-game': list = listModMiniGame; break;
        default: list = listModVideo; break;
      }
    }

    switch (list[stepper?.step ?? 0] ?? 0) {
      case 0 : setOpenStatus(false); return <div/>;
      case 1 : return <ChoiceMod/>; //choix element 3D
      case 2 : return <ChoiceImage/>;
      case 3 : return <ConfigPhoto/>; //si vidéo
      case 4 : return <ListCreation/>;
      case 5 : return <ValidateCustom/>;
      default: return <ValidateCustom/>;
    }
  }

  const nextStep = () => {
    stepper?.setStep((oldStep) => oldStep + 1);
  }

  const previousStep = () => {
    stepper?.setStep((oldStep) => oldStep - 1);
  }

  const nameBtn = () => {
    if(stepper) {
      return stepper?.step < 4 ? t('next') : t('save')
    }
    return t('next');
  }

  useEffect(() => {
    if(stepper) {
      if(stepper.step === 5) {
        apiImage();
      }
    }
  }, [stepper?.step])

  const apiImage = () => {
    uploadImage(stepper?.object.image).then((response) => {

      if(stepper) {
        switch (stepper.object.type) {
          case 'video': apiVideo(response.data.id); break;
          case 'image': apiObjet(response.data.id); break;
          case 'mini-game': apiMiniGame(response.data.id); break;
          default: break;
        }
      }
    }).catch((error) => {
      showToast({
        message: t("errorUpload"),
        severity: 'error',
      });
    });
  }

  const apiVideo = (idImage: string) => {
    uploadVideo(stepper?.object.video).then((response) => {
      const customdata ={
        "photo": idImage,
        "video": response.data.id,
        "type": "video"
      };
      customApi(customdata);
    })
  }

  const apiObjet = (idImage: string) => {
    const customdata = {
      "photo": idImage,
      "asset": stepper?.object?.asset,
      "type": "object"
    };
    customApi(customdata);
  }

  const apiMiniGame = (idImage: string) => {
    const customdata = {
      "photo": idImage,
      "asset": stepper?.object?.asset,
      "type": "mini-game"
    };
    customApi(customdata);
  }

  const customApi = (customdata : Custom) => {
    custom(customdata).then((response) => {
      showToast({
        message: t("customOk"),
        severity: 'success',
      });
    }).catch(() => {
      showToast({
        message: t("customKO"),
        severity: 'error',
      });
    }).finally(() => setOpenStatus(false));
  }

  return(
    <div className="width100 heigthStepper borderBox">
      {changeStep()}
      <div className="flexHorizontal gap40 mV16">
        <Button onClick={() => previousStep()} text={t('previous')} color="white" background="#BCC0FF"
                backgroundHover="#5C72D8"/>
        <Button onClick={() => nextStep()} text={nameBtn()} color="white" background="#BCC0FF"
                backgroundHover="#5C72D8" disable={stepper?.nextStep}/>
      </div>
    </div>
  );
}

export default StepperShop;