import './Creator.scss';
import {useTranslation} from "react-i18next";
import React, { useState } from 'react';
import Button from "../../components_global/button/Button";
import DragNDrop from '../../components/drag&drop/DragNDrop';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const Creator = () => {
  const {t} = useTranslation();
  const [file, setFile] = useState<File>();

  const handleDropFile = (files: FileList | null) => {
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  return(
    <div className="mH50 creator">
      <div className="mT50 fontSize16 bold">{t("DEPOT")}</div>
      <div className="mT50 flexHorizontal"><DragNDrop defaultText={t('drapDrop')} onDropFile={handleDropFile}/></div>
      <div className="mT50 fontSize16 bold">{t("Description")}</div>
      <div className="mT10 textarea-container">
        <BaseTextareaAutosize aria-label="empty textarea" placeholder=""/>
      </div>
      <div className="flexHorizontal gap40 mT50">
        <Button text={t("Annuler")} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'} onClick={()=>console.log('Goodbye')}></Button>
        <Button text={t("Deposer")} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'} onClick={()=>console.log('Hello')}></Button>
      </div>    
    </div>
  );
}

export default Creator;