import './DragNDrop.scss';
import React, {useState, ChangeEvent, DragEvent} from 'react';

interface DragNDropProps {
  defaultText: string;
  onDropFile?: (files: FileList | null) => void;
  handleFile?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  accept?: string;
}

const DragNDrop: React.FC<DragNDropProps> = ({defaultText, onDropFile, handleFile, accept}) => {
  const [dragActive, setDragActive] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [fileContents, setFileContents] = useState<(string | ArrayBuffer | null)[]>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFileInput = async function (event: React.DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if ('files' in event.target && event.target.files && handleFile) {
      await handleFile(event as ChangeEvent<HTMLInputElement>)
    }

    const files = 'dataTransfer' in event ? event.dataTransfer.files : event.target.files;

    if (files && files.length > 0) {
      const fileNamesArray: string[] = [];
      const fileContentsArray: (string | ArrayBuffer | null)[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        fileNamesArray.push(file.name);

        reader.onload = (loadEvent) => {
          const result = loadEvent.target?.result;
          if (result) {
            fileContentsArray.push(result);
            setFileContents([...fileContentsArray]);
          } else {
            fileContentsArray.push(null);
            setFileContents([...fileContentsArray]);
          }
        };

        if (file.type.startsWith('image') && (file.type === 'image/jpeg' || file.type === 'image/png')) {
          reader.readAsDataURL(file);
        } else if (file.type.startsWith('video') && file.type === 'video/mp4') {
          reader.readAsDataURL(file);
        } else {
          reader.readAsArrayBuffer(file);
        }
      }

      setFileNames(fileNamesArray);
      if (onDropFile) {
        onDropFile(files);
      }
    }
  };

  return (
    <div id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleFileInput} accept={accept} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
        <div>
          {fileNames.length > 0 ?
            fileNames.map((fileName, index) => {
              const fileExtension = fileName.split('.').pop()?.toLowerCase();
              const fileContent = fileContents[index];

              if (fileExtension && typeof fileContent === 'string') {
                if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
                  return renderImage(fileContent, fileName, index);
                } else if (fileExtension === 'mp4') {
                  return renderVideo(fileContent, fileName, index);
                } else {
                  return renderDefault(fileName, fileExtension, index);
                }
              } else {
                return renderDefault(fileName, fileExtension, index);
              }
            }) :
            defaultText
          }
        </div>
      </label>
      {dragActive &&
        <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag}
             onDrop={handleFileInput}></div>}
    </div>
  );
};

const renderImage = (fileContent: string, fileName: string, index: number) => (
  <div key={index}>
    <img src={fileContent} alt={fileName} style={{maxWidth: '200px', maxHeight: '300px'}}/>
  </div>
);

const renderVideo = (fileContent: string, fileName: string, index: number) => (
  <div key={index}>
    <video controls style={{maxWidth: '1000px'}}>
      <source src={fileContent} type="video/mp4"/>
      Your brower does not support the video tag or your video is not in the right format.
    </video>
  </div>
);

const renderDefault = (fileName: string, fileExtension: string | undefined, index: number) => (
  <div key={index}>
    <span>{fileName}</span> - <span>{fileExtension}</span>
  </div>
);

export default DragNDrop;
