import './Community.scss'
import React from 'react';
import {DialogProvider} from "../../context/DialogContext";
import ListCreation from "../../components/list_creation/ListCreation";

const Community = () => {

  return(
    <DialogProvider>
      <div className="mH50 community">
        <ListCreation/>
      </div>
    </DialogProvider>
  );
}

export default Community;