import './ShopHome.scss';
import React from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../components_global/button/Button";
import {useNavigate} from "react-router-dom";

const ShopHome = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="shopHome mB50">
      <h1 className="mV20 textColorSecondary uppercase">{t('menu.shop')}</h1>
      <div className="grid mT50">
        <div className="album radius10"/>
        <div className="flexVerticalStart gap40">
          <h2 className="fontWeight500">{t('albumPhoto')}</h2>
          <p className="fontWeight400">{t('givePhoto')}</p>
          <div className="flexHorizontal width100">
            <Button onClick={() => navigate('/shop')} text={t('createAlbum')} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'}/>
          </div>
        </div>
        <div className="flexVerticalStart gap40">
          <h2 className="fontWeight500">{t('simplePhoto')}</h2>
          <p className="fontWeight400">{t('printMoment')}</p>
          <div className="flexHorizontal width100">
            <Button onClick={() => navigate('/shop')} text={t('createPhoto')} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'}/>
          </div>
        </div>
        <div className="simple radius10"/>
      </div>
    </div>
  );
}

export default ShopHome;