import './Filters.scss'
import Filter from "../filter/Filter";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import { DialogContext } from '../../context/DialogContext';
import MoreFilters from "../Dialog/MoreFilters";

const Filters = () => {
  const {t} = useTranslation();
  const dialog = useContext(DialogContext);
  const [listFilters, setListFilters] = useState([
    {
      text: "Objets",
      select: true,
    },
    {
      text: "Mini-jeux",
      select: false,
    },
  ]);

  const handleFilterSelect = (index: number, selected: boolean) => {
    const updatedFilters = listFilters.map((filter, idx) => ({
      ...filter,
      select: idx === index ? selected : false,
    }));

    setListFilters(updatedFilters);
  };

  const openMoreFilter = () => {
    const dialogParam = <MoreFilters />;
    dialog?.openDialog(dialogParam);
  }

  return(
    <div className="flexHorizontal gap10 wrap filters">
      {listFilters.map((item, index) => (
        <Filter key={index} text={item.text} select={item.select} setSelect={(selected) => handleFilterSelect(index, selected)} />
      ))}
      <div className="fontSize14 pointer" onClick={() => openMoreFilter()}>{t('moreFilters')}</div>
    </div>
  );
}

export default Filters;