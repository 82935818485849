import React, {useEffect, useState} from "react";
import {listFavorite} from "../../services/favoriteAPI";
import Card from "../card/Card";
import {Objet3D} from "../../type/type";

const Crush = () => {
  const [listObjet, setListObjet] = useState<Objet3D[] | undefined>();

  useEffect(() => {
    listFavorite().then(r => {
      setListObjet(r.data);
    });
  }, []);

  return(
    <div>
      <div>Coup de coeur</div>
     
    </div>
  );
}

export default Crush;