import React, { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRoutesProps {
  isAllowed: boolean;
}

/* Check whether the route is visible or not with respect to isAllowed */
const PrivateRoutes: FC<PrivateRoutesProps> = ({ isAllowed }) => {
  return isAllowed ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;