import React, {createContext, FC, ReactElement, ReactNode, useState} from "react";


export interface DialogContextProps {
  openDialog: (param: ReactNode) => void;
  closeDialog: () => void;
  dialogParam: ReactNode;
}

interface DialogProviderProps {
  children: ReactElement | ReactElement[]
}

export let DialogContext = createContext<DialogContextProps | null>(null);

export const DialogProvider:FC<DialogProviderProps> = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogParam, setDialogParam] = useState<ReactNode | null>(null);

  // Fonction pour ouvrir la boîte de dialogue avec un paramètre
  const openDialog = (param: ReactNode) => {
    setIsDialogOpen(true);
    setDialogParam(param);
  };

  // Fonction pour fermer la boîte de dialogue
  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialogParam(null);
  };

  const value: DialogContextProps = {
    openDialog,
    closeDialog,
    dialogParam,
  };

  return (
    <DialogContext.Provider value={value}>
      {children}
      {isDialogOpen && dialogParam}
    </DialogContext.Provider>
  );
}