import './Shop.scss'
import React, {useState} from 'react';
import ChoiceCommand from "../../components/choice_command/ChoiceCommand";
import StepperShop from "../../components/stepper_shop/StepperShop";
import {StepperProvider} from "../../context/StepperContext";

const Shop = () => {
  const [open, setOpen] = useState<Boolean>(false);

  const handleOpenChange = (newOpenStatus: boolean) => {
    setOpen(newOpenStatus);
  };

  return(
    <StepperProvider>
      <div className="shop">
        {open ?
          <StepperShop setOpenStatus={handleOpenChange}/>
          :
          <ChoiceCommand setOpenStatus={handleOpenChange}/>
        }
      </div>
    </StepperProvider>
  );
}

export default Shop;