import React from "react";

export const LegalNotices = {
  text:
    <div>
      <div className="mT50 fontWeight800 flexHorizontal mB50">Mentions légales</div>
      <div className="flexVerticalStart gap10">
        <div>Éditeur</div>
        <div>Lifoto</div>
        <div>EPITECH LILLE,</div>
        <div>101 Rue de l'Hôpital Militaire, 59000 Lille</div>
        <div>Mail 1: theo.miquet@epitech.eu</div>
        <div>Mail 2: theo.nol-ygonnin@epitech.eu</div>
        <div>Mail 3: lifoto@epitechfr.onmicrosoft.com</div>
        <div>Hébergeur</div>
        <div>Théo MIQUET</div>
        <div>EPITECH LILLE,</div>
        <div>101 Rue de l'Hôpital Militaire, 59000 Lille</div>
        <div>Mail : theo.miquet@epitech.eu</div>
        <div className="mB50">Cet hébergeur détient à ce jour les informations personnelles concernant l’auteur de ce site.</div>
      </div>

    </div>
}