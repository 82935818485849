import React, {createContext, FC, ReactElement, useContext, useState} from 'react';
import Toast from "../components_global/toast/Toast";

// Définir le type des paramètres du Toast
interface ToastParams {
  message: string;
  severity: 'success' | 'error';
}

// Créer le contexte pour gérer le Toast
export let ToastContext = createContext<{ showToast: (params: ToastParams) => void; }>({
  showToast: () => {},
});

export interface ToastProviderProps {
  children: ReactElement | ReactElement[]
}

// Fonction pour afficher le Toast
export const ToastProvider:FC<ToastProviderProps> = ({ children }) => {
  const [toastParams, setToastParams] = useState<ToastParams | null>(null);

  // Fonction pour afficher le Toast avec les paramètres personnalisés
  const showToast = (params: ToastParams) => {
    setToastParams(params);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toastParams && (
        <Toast
          message={toastParams.message}
          severity={toastParams.severity}
          open={true}
          setOpen={() => setToastParams(null)}
        />
      )}
    </ToastContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte du Toast
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};