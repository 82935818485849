import './ListCreation.scss';
import Filters from "../filters/Filters";
import SearchBar from "../../components_global/searchBar/SearchBar";
import Card from "../card/Card";
import React, {useContext, useEffect, useState} from "react";
import {getobject} from "../../services/customAPi";
import {Objet3D} from "../../type/type";
import {AuthContextType} from "../../context/AuthContext";
import {useAuth} from "../app/App";
import {StepperContext} from "../../context/StepperContext";

const ListCreation = () => {
  const [objet, setObjet] = useState<Objet3D[] | undefined>();
  const auth: AuthContextType | null = useAuth();
  const stepper = useContext(StepperContext);

  const handleCardClick = (id: string) => {
    if(stepper) {
      stepper.setObject({
        ...stepper.object,
        asset: id,
      });

      stepper.setNextStep(false);

    }
  };

  useEffect(() => {
    getobject().then((res) => {
      setObjet(res);
      }).catch((error) => {
        console.error(error);
      }
    );
  }, []);

  return(
    <div className="m8">
      <div className="mT50 mB50 flexSpaceBetween wrap">
        <Filters/>
        <SearchBar/>
      </div>
      <div className="listCard">
        {objet && objet.map((item) => (
          <Card data={item} key={item.id} isLoveHidden={!auth?.isConnected} onCardClick={handleCardClick}/>
        ))}
       </div>
    </div>
  );
}

export default ListCreation;