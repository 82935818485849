import './CommunityHome.scss';
import React from 'react';
import {useTranslation} from "react-i18next";

const CommunityHome = () => {
  const {t} = useTranslation();

  const listCommunity = [
    {
      img: "https://source.unsplash.com/VWcPlbHglYc",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/O0N9MF--hK4",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/FV3GConVSss",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/0ESjL-Nw22Y",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/KTVn62x6fFw",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/VSeVhmW4_JQ",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/07aFaTf24Kg",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/DqyYTM7pR2o",
      title: "titre image galerie"
    },
    {
      img: "https://source.unsplash.com/IdNOTjPeHrE",
      title: "titre image galerie"
    },
  ];
  const columns = Math.ceil(listCommunity.length / 3);

  return(
    <div className="communityHome">
      <h1 className="mV20 textColorSecondary uppercase">{t('menu.community')}</h1>
      <div className="gallery">
        {[...Array(columns)].map((_, columnIndex) => (
          <div className="column" key={columnIndex}>
            {listCommunity.slice(columnIndex * 3, columnIndex * 3 + 3).map((item, index) => (
              <div className="imageItem" key={index}>
                <img src={item.img} alt="" />
                <div className="overlay"><span>{item.title}</span></div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CommunityHome;