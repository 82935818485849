import './Settings.scss'
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Profil from "../../components/profil/Profil";
import Commands from "../../components/commands/Commands";
import Coordinate from "../../components/coordinate/Coordinate";
import Crush from "../../components/crush/Crush";
import {useNavigate} from "react-router-dom";

interface SettingsProps {
  defaultDisplay : string,
}

interface ParamsProps {
  id: string,
  text: string,
  url: string,
}

const Settings:FC<SettingsProps> = ({defaultDisplay}) => {
  const {t} = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string>(defaultDisplay);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedItem(defaultDisplay);
    displayParam();
  }, [defaultDisplay]);

  const handleClick = (item: ParamsProps) => {
    setSelectedItem(item.id);
    navigate(item.url);
  };

  const listCommunity = [
    {
      id: "profil",
      text: "menu.profil",
      url: "/settings",
    },
    {
      id: "commands",
      text: "yourCommands",
      url: "/commands",

    },
    {
      id: "coordinate",
      text: "coordinate",
      url: "/coordinate",
    },
    {
      id: "crush",
      text: "crush",
      url: "/crush",
    },
  ];

  const displayParam = () => {
    switch (selectedItem) {
      case 'profil': return <Profil/>;
      case 'commands': return <Commands/>;
      case 'coordinate': return <Coordinate/>;
      case 'crush': return <Crush/>;
      default: return <Profil/>
    }
  }

  return(
    <div className="settings width100">
      <div className="width20">
        <h1 className="fontSize20">{t("menu.setting")}</h1>
        <div className="flexVertical gap40 menu fontSize18">
          {listCommunity.map((item) => (
            <div className={selectedItem === item.id ? "select" : ""} onClick={() => handleClick(item)}>{t(item.text)}</div>
          ))}
        </div>
      </div>
      <div className="border"/>
      <div className="width80 paramsItem borderBox overflowY">
        {displayParam()}
      </div>
    </div>
  );
}

export default Settings;