import './Opinion.scss';
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import test from '../../assets/images/home/simplePhoto.png'

const Opinion = () => {
  const {t} = useTranslation();
  const [opinion, setOpinion] = useState([
    {
      name: "Chloé",
      date: "12/12/2023",
      opinion: "C'est cool",
      image: "../../assets/images/home/albumPhoto.png"
    },
    {
      name: "Théo",
      date: "04/10/2023",
      opinion: "Lifoto a transformé mes souvenirs en une expérience visuelle magique! La réalité augmentée et l'IA ajoutent une touche unique. Merci Lifoto!",
      image: "../../assets/images/home/simplePhoto.png"
    },
  ]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return(
    <div className="opinion flexHorizontal textColorWhite">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        infinite={true}
        className="carousel"
      >
        {opinion.map((item, idItem) => (
          <div key={'opinionItem' + idItem} className="flexHorizontal textColorWhite gap40">
            <img src={test} alt="Image profil"/>
            <div className="flexVertical width50">
              <div className="fontSize14 textAlignCenter mB50">{item.opinion}</div>
              <div className="fontSize12">{item.name},{item.date}</div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Opinion;