import React, {useState} from 'react';
import './Beta.scss';
import LifotoLongLogo from "../../assets/icons/LifotoLongLogo";
import Button from "../../components_global/button/Button";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {isEmailValid} from "../../utils/regex";

const Beta = () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [register, setRegister] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(true);

  const validateRegister = () => {
    const validate = isEmailValid(email);
    if(validate) {
      setValidate(true);
      setRegister(true);
    } else {
      setValidate(false);
    }
  }

  return (
    <div className="beta zindex0 relative flexVertical">
      <div className="bgBlur absolute"/>
      <LifotoLongLogo color="black" size={500} className="logo mT50"/>
      { register ?
        <div className="fontSize30 fontWeight400 flexHorizontal gap5">
          <div>{t("thankRegister")}</div>
          <div className="textColorSecondary">{email}</div>
          <div>!!</div>
        </div>
        :
        <div className="fontSize30 fontWeight400 gap20 flexVertical">
          <div>{t("textRegisterBeta")}</div>
          <div>{t("lifePicture")}</div>
          <div className="flexVertical gap20 mT50 relative">
            <TextField id="outlined-basic" label={t("email")} variant="outlined"
                       value={email}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setEmail(event.target.value);
                       }}/>
            <Button text={t("register")} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'} onClick={() => validateRegister()}/>
            {!validate && (
              <div className="textColorRed fontSize12 absolute messageError">{t("invalidEmail")}</div>
            )}
          </div>
        </div>
      }
    </div>
  );
}

export default Beta;