import React, { FC } from 'react';
import './ConditionGeneral.scss'
import Footer from "../../components/footer/Footer";

interface ConditionGeneralProps {
  text: any,
}

const ConditionGeneral:FC <ConditionGeneralProps> = ({text}) => {
  return (
    <div>
      <div className="width80 m0auto">{text}</div>
      <Footer/>
    </div>
  );
}

export default ConditionGeneral;