import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./transation/en.json";
import frTranslations from "./transation/fr.json";

export const myTranslation = (key: string, count: number = 1, options?: { capitalize?: boolean, pluralize?: boolean }): string => {
  let translation = i18n.t(key, { count });

  if (options && options.capitalize) {
    translation = translation.charAt(0).toUpperCase() + translation.slice(1);
  }

  if (options && options.pluralize) {
    const pluralTranslation = translation.split('||');
    translation = count === 1 ? pluralTranslation[0] : pluralTranslation[1];
  }

  return translation;
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    fr: {
      translation: frTranslations,
    },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;