import './Timeline.scss'
import {FC} from "react";
import React from 'react';
import {useTranslation} from "react-i18next";
import timeline from "../../assets/images/timeline/timeline.svg";
import Footer from "../../components/footer/Footer";

const Timeline:FC = () => {
  const {t} = useTranslation();

  return (
    <div className="timeline zindex0 relative flexSpaceBetween flexVertical">
      <div className="bgBlur absolute"/>
      <div className="textAlignCenter fontSize30 mV50">{t("roadmap")}</div>
      <div className="flexHorizontal mB50">
        <img className="heightAuto" src={timeline} alt="timeline de lifoto"/>
      </div>
      <Footer/>
    </div>
  );
}

export default Timeline;