import './Header.scss';
import React from 'react';
import LifotoLongLogo from "../../assets/icons/LifotoLongLogo";
import Button from "../../components_global/button/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Header = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return(
    <header className="flexVerticalEndCenter">
      <div className="flexVertical gap50">
        <LifotoLongLogo color="black" size={600} className="logo"/>
        <div>{t('photoLife')}</div>
        <div className="flexHorizontal gap20">
          <Button onClick={() => navigate('/team')} text={t('ourTeam')} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'}/>
          <Button onClick={() => navigate('/timeline')} text={t('ourTimeline')} color={'white'} background={'#5C72D8'} backgroundHover={'#6419B9'}/>
        </div>
        <span className="zindex1 material-symbols-outlined textColorTernary arrow">
        arrow_downward
      </span>
      </div>
    </header>
  );
}

export default Header;