import axios from "axios";

export const createFavorite = (id: string) => {

  return axios.post(`${process.env.REACT_APP_URL_API}/favorite`, {
    "articles_id": id,
  },{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};

export const listFavorite = () => {

  return axios.get(`${process.env.REACT_APP_URL_API}/favorite/list`,{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};

export const deleteFavorite = (id: string) => {

  return axios.get(`${process.env.REACT_APP_URL_API}/favorite/${id}`,{
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem("token_access")}`
    }
  }).then((response) => {
    return response
  })
};