import React, { useContext } from 'react';
import './App.scss';
import {BrowserRouter} from "react-router-dom";
import Router from "../../router/Router";
import Menu from '../menu/Menu';
import {AuthContext, AuthContextType, AuthProvider} from "../../context/AuthContext";
import {ToastContext, ToastProvider, ToastProviderProps} from "../../context/ToastContext";

const App = () => {

  return (
    <AuthProvider>
      <ToastProvider>
        <BrowserRouter>
          <Menu/>
          <Router/>
        </BrowserRouter>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;

export const useAuth = (): AuthContextType | null => {
  return useContext(AuthContext);
}

export const useToast = (): any | null => {
  return useContext(ToastContext);
}