import './Home.scss'
import React from 'react';
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer';
import Discover from "../../components/discover/Discover";
import ShopHome from "../../components/shop_home/ShopHome";
import Opinion from "../../components/opinion/Opinion";
import CommunityHome from "../../components/community_home/CommunityHome";

const Home = () => {
  return(
    <div className="relative zindex0">
      <div className="bgBlur absolute"/>
      <div className="zindex1">
        <Header/>
        <Discover/>
        <ShopHome/>
        <Opinion/>
        <CommunityHome/>
        <Footer/>
      </div>
    </div>
  );
}

export default Home;