import './ChoiceMod.scss';
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import video from "../../assets/images/shop/modVideo.svg"
import objet from "../../assets/images/shop/modObjet.svg"
import game from "../../assets/images/shop/modGame.svg"
import {StepperContext} from "../../context/StepperContext";

const ChoiceMod = () => {
  const {t} = useTranslation();
  const stepper = useContext(StepperContext);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectIndex, setSelectIndex] = useState<number | null>(null);

  useEffect(() => {
    if(stepper && stepper.object.type === '') {
      stepper.setNextStep(true);
    }
    if(stepper && stepper.object.type !== '') {
      switch (stepper.object.type) {
        case 'video': setSelectIndex(0); break;
        case 'image': setSelectIndex(1); break;
        case 'mini-game': setSelectIndex(2); break;
        default: setSelectIndex(0); break;
      }
    }
  }, []);

  const mod = [
    {
      type: t('modVideo'),
      desc: t('descVideo'),
      image: video
    },
    {
      type: t('modObjet'),
      desc: t('descObjet'),
      image: objet
    },
    {
      type: t('modGame'),
      desc: t('availableSoon'),//t('descGame'),
      image: game
    },
  ];

  const setSelectMod = (index: number) => {
    setSelectIndex(index);
    let type = 'image';

    switch (index) {
      case 0: type = 'video'; break;
      case 1: type = 'image'; break;
      case 2: type = 'mini-game'; break;
      default: type = 'image'; break;
    }

    if(stepper) {
      stepper.setObject({
        ...stepper.object,
        type: type,
      });

      stepper.setNextStep(false);
    }

  }

  return (
    <div className="choiceMod">
      <div className="fontSize18 uppercase fontWeight600 textAlignCenter">
        {t('choiceImage')}<span className="textColorSecondary">{t(stepper?.name ?? 'aSimpleImage')}</span>
      </div>
      <div className="fontSize20 uppercase textColorSecondary fontWeight800 textAlignCenter mT32">{t('optionCustomization')}</div>
      <div className="flexHorizontal gap50 mT50">
        {mod.map((item, index) => (
          <div className={`flexVertical gap20`} key={index}>
            <div className={`card flexVertical gap10 p16 ${selectIndex === index ? 'clicked' : ''} ${index === 2 ? 'disabledBtn' : ''}`}
                 onMouseEnter={() => setHoveredIndex(index)}
                 onMouseLeave={() => setHoveredIndex(null)}
                 onClick={() => setSelectMod(index)}
            >
              <img src={item.image} alt="image mode"/>
              <div className="fontSize20 uppercase textColorPrimary fontWeight500">{item.type}</div>
            </div>
            <div className="description">
              {hoveredIndex === index && (
                <div className="fontSize14 textAlignCenter lineHeight">{item.desc}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChoiceMod;