import './Discover.scss';
import React from 'react';
import {useTranslation} from "react-i18next";
import replay from "../../assets/images/home/replay.png";
import customize from "../../assets/images/home/customize.png";
import help from "../../assets/images/home/help.png";

const Discover = () => {
  const {t} = useTranslation();

  return(
    <div className="discover">
      <div className="fontSize14 flexVertical gap50 mV100">
        <div className="textAlignCenter">{t('whySolution')}</div>
        <div className="flexHorizontal gap50 wrap">
          <div className="flexVerticalStartCenter gap40">
            <div className="blurPosition">
              <div className="blurInnovation purpleBlur"/>
              <div className="icon">
                <img alt="replay" src={replay}/>
              </div>
            </div>
            <div className="text textAlignCenter fontSize14">{t('bestMoments')}</div>
          </div>
          <div className="flexVertical gap40">
            <div className="blurPosition">
              <div className="blurInnovation blueBlur"/>
              <div className="icon">
                <img alt="customisation" src={customize}/>
              </div>
            </div>
            <div className="text textAlignCenter fontSize14">{t('unlimitedCustomisation')}</div>
          </div>
          <div className="flexVertical gap40">
            <div className="blurPosition">
              <div className="blurInnovation darkBlueBlur"/>
              <div className="icon">
                <img alt="aide" src={help}/>
              </div>
            </div>
            <div className="text textAlignCenter fontSize14">{t('selfHelp')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discover;