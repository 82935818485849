import Button from "../../components_global/button/Button";
import React, {FC, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import './ChoiceCommand.scss';
import {StepperContext} from "../../context/StepperContext";

interface ChoiceCommandProps {
  setOpenStatus: (newOpenStatus: boolean) => void;
}

const ChoiceCommand: FC<ChoiceCommandProps> = ({ setOpenStatus }) => {
  const {t} = useTranslation();
  const stepper = useContext(StepperContext);

  const openShop = (option:string) => {
    stepper?.setName(option);
    stepper?.setStep(1);
    setOpenStatus(true);
  }

  return(
    <div className="choiceCommand flexHorizontal overflowAuto">
      <div className="album flexVertical gap20">
        <div className="fontSize30 textColorWhite uppercase fontWeight800">{t('albumPhoto')}</div>
        <Button onClick={() => openShop('aPhotoAlbum')} text={t('order')} color="white" background={'#5C72D8'} backgroundHover={'#6419B9'}/>
      </div>
      <div className="separator"/>
      <div className="simple flexVertical gap20">
        <div className="fontSize30 textColorWhite uppercase fontWeight800">{t('simplePhoto')}</div>
        <Button onClick={() => openShop('aSimpleImage')} text={t('order')} color="white" background={'#5C72D8'} backgroundHover={'#6419B9'}/>
      </div>
    </div>
  );
}

export default ChoiceCommand;