import React, { FC } from 'react';
import './Filter.scss';

interface FilterProps {
  text: string,
  select: boolean,
  setSelect: (select: boolean) => void,
}

const Filter:FC<FilterProps> = ({text, select, setSelect}) => {
  const deleteFilter = () => {
    //console.log("click")
  }

  const changeSelect = () => {
    setSelect(!select);
  }

  return(
    <div className={`${select ? "select" : "noSelect"} filter flexHorizontal gap10 textColorWhite pointer fontSize18`} onClick={() => changeSelect()}>
      <div>{text}</div>
      {select ? (
        <span className="material-symbols-outlined" onClick={() => deleteFilter()}>close</span>
      ) : null}
    </div>
  );
}

export default Filter;