import {useCallback, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {Token} from "../type/type";

const useRefreshToken = () => {
  const [validateToken, setValidateToken] = useState<boolean>(false);

  const refresh = useCallback(() => {
    let id = 0;
    const token = sessionStorage.getItem("token_access");
    if(sessionStorage.getItem("token_access") && sessionStorage.getItem("token_access") !== undefined) {
      const decoded = jwt_decode<Token>(token ?? "");
      const exp = decoded.exp;
      const dateTime = Math.floor(Date.now() / 1000);

      if(decoded && decoded.exp) {
        setValidateToken(exp > dateTime);
      }
    }
  }, [validateToken]);

  useEffect(refresh,[refresh]);

  return { validateToken, refresh };
}

export default useRefreshToken;