import './ChoiceImage.scss'
import React, {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import Button from "../../components_global/button/Button";
import {useTranslation} from "react-i18next";
import {StepperContext} from "../../context/StepperContext";
import DragNDrop from "../drag&drop/DragNDrop";

const ChoiceImage = () => {
  const {t} = useTranslation();
  const stepper = useContext(StepperContext);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileObj, setFileObj] = useState<File | null>(null);
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  useEffect(() => {
    if (stepper) {
      if(!stepper.object.image) {
        stepper.setNextStep(true);
      } else {
        stepper.setNextStep(false);
        setSelectedFile(URL.createObjectURL(stepper.object.image));
      }
    }
  }, []);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setFileObj(file);

    if (!file) {
      return;
    }
    setVideoLoading(true);


    const fileUrl = URL.createObjectURL(file);
    // 👇️ reset file input
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setSelectedFile(prevValue => fileUrl);
    setFileType(file.type);
    setFileObj(file); // Update fileObj state

    if(stepper) {
      stepper.setNextStep(false);
      stepper.setObject({
        ...stepper.object,
        image: file,
      });

      // 👇️ Perform null check before accessing properties
    if (fileObj) {
      // Access properties safely
      //console.log("fileObj is", fileObj);
      //TODO save URL dans objet stepper
      // Other logic...
      }
    }
  };

  const handleDropFile = (files: FileList | null) => {
    if (inputRef.current) {
      inputRef.current.click();
      //handleFileChange();
    }
  };

  return(
    <div className="choiceImage borderBox">
      <div className="textAlignCenter textColorSecondary fontWeight600 p40">{t('pleaseUpload')}</div>
      <div className="flexVertical">
        <DragNDrop defaultText={t('drapDrop')} onDropFile={handleClick} handleFile={handleFileChange} accept='image/*'/>
      </div>
    </div>
  );
}

export default ChoiceImage;