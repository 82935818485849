import './Toast.scss';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { FC } from 'react';
import {SyntheticEvent} from 'react';

interface ToastProps {
  message: string,
  severity: AlertColor, //success or error
  open: boolean,
  setOpen: (value: boolean) => void;
}

const Toast:FC<ToastProps> = ({message, severity='success', open=false, setOpen}) => {
  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return(
    <Snackbar
      open={true}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <div>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
        {message}
        </Alert>
      </div>
    </Snackbar>
  );
}

export default Toast;