import React, {ChangeEvent, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../components_global/button/Button";
import './ConfigPhoto.scss';
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import { Slider} from "@mui/material";
import { Mark } from "../../type/type";
import {formatSecondeTime} from "../../utils/formatting";
import {useToast} from "../../context/ToastContext";
import {StepperContext} from "../../context/StepperContext";
import DragNDrop from "../drag&drop/DragNDrop";

const ConfigPhoto = () => {
  const {t} = useTranslation();
  const { showToast } = useToast();
  const stepper = useContext(StepperContext);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [fileObj, setFileObj] = useState<File | null>(null);
  const [start, setStart] = useState<string>("00:00:00");
  const [end, setEnd] = useState<string>("00:00:10");
  const [modifyVideo, setModifyVideo] = useState<boolean>(false);
  const [activeCutVideo, setActiveCutVideo] = useState<boolean>(false);
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const [fileModify, setFileModify] = useState<string | undefined>(undefined);
  const [duration, setDuration] = useState<number>(0);
  const [framePoints, setFramePoints] = useState<string[]>([]);
  const [minDistance, setMinDistance] = useState<number>(0);
  const [time, setTime] = useState<number[]>([20, 37]);
  const [marks, setMarks] = useState<Mark[]>(
    [{
      value: 0,
      label: '00:00:00',
    },
    {
      value: 100,
      label: '00:00:00',
    }]
  );

  useEffect(() => {
    if(stepper && !stepper.object.video) {
      stepper.setNextStep(true);
    }
  }, []);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    setFileObj(file);

    if (!file) {
      return;
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const allowedVideoExtensions = ['mp4', 'mov', 'avi', 'mkv'];

    if (fileExtension && allowedVideoExtensions.includes(fileExtension)) {
      setVideoLoading(true);


      const fileUrl = URL.createObjectURL(file);
      // 👇️ reset file input
      if (inputRef.current) {
        inputRef.current.value = '';
      }

      setSelectedFile(prevValue => fileUrl);
      setFileType(file.type);
      setFileObj(file); // Update fileObj state

      // 👇️ Perform null check before accessing properties
      if (fileObj) {
        // Access properties safely
        //console.log("fileObj is", fileObj);
        // Other logic...
      }
    } else {
      showToast({
        message: t("errorFormatVideo"),
        severity: 'error',
      });
    }
  };

  const cutVideo = async () => {
    setActiveCutVideo(true);

    if(fileObj) {
      const ffmpeg = new FFmpeg();

      try {
        // Charger FFmpeg
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd'
        await ffmpeg.load({
          coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
          wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });

        const inputFileName = fileObj.name;
        // Charger la vidéo
        await ffmpeg.writeFile(inputFileName, await fetchFile(fileObj));

        // Découper la vidéo
        const args = ["-i", inputFileName, "-ss", start, "-to", end, `${inputFileName}.mp4`];
        await ffmpeg.exec(args);

        // Récupérer la vidéo découpée
        const data = ffmpeg.readFile(`${inputFileName}.mp4`);
        const fileData = await data;
        const blob = new Blob([fileData], { type: "video/mp4" }) as Blob;
        const modifiedVideoUrl = URL.createObjectURL(blob);

        setModifyVideo(true);
        if(modifiedVideoUrl) setFileModify(modifiedVideoUrl);

        const video = new File([blob], `${inputFileName}.mp4`,{ type: "video/mp4" });
        if(stepper) {
          stepper.setObject({
            ...stepper.object,
            video: video,
          });
          stepper.setNextStep(false);
        }

      } catch (error) {
        console.error('Erreur lors des opérations FFmpeg :', error);
      }

    }
  }

  useEffect(() => {
    if (selectedFile) {
      const videoElement = document.createElement('video');

      if (selectedFile) {
        videoElement.src = selectedFile;

        videoElement.onloadedmetadata = () => {
          const videoDuration = Math.floor(videoElement.duration);
          setDuration(videoDuration);
        };
      }
    }
  }, [selectedFile]);

  useEffect(() => {
    if (duration > 0) {
      setMarks([{
        value: 0,
        label: '00:00:00',
      }, {
          value: duration,
          label: formatSecondeTime(duration),
      }]);

      const videoElement = document.createElement('video');
      const timePoints: number[] = Array.from({ length: duration }, (_, index) => index + 1);
      const pointsDisplay = selectItemsAtRegularIntervals(timePoints);
      // Récupérer les frames pour chaque point de temps
      let imageUrl:any;
      pointsDisplay.forEach((time) => {
        getFrame(time, videoElement);
      })
    }
  }, [duration])

  const selectItemsAtRegularIntervals = (list: number[]): number[] => {
    const interval = Math.floor(list.length / 10);
    const selectedItems: number[] = [];

    for (let i = 0; i < list.length; i += interval) {
      selectedItems.push(list[i]);
    }

    return selectedItems;
  };

  const getFfmpeg = useCallback(async (fileObj: any): Promise<FFmpeg> => {
    const ffmpeg = new FFmpeg();
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    await ffmpeg.writeFile(fileObj.name, await fetchFile(fileObj));
    return ffmpeg
  }, [])

  // Récupérer les frames aux moments spécifiés
  const getFrame = async (time: number, videoElement: HTMLVideoElement) => {
    const ffmpeg = new FFmpeg();

    try {
      // Charger FFmpeg
      const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
      await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      });

      if (selectedFile && fileObj) {
        const inputFileName = fileObj.name;
        // Charger la vidéo
        await ffmpeg.writeFile(inputFileName, await fetchFile(fileObj));

        const args = ["-i", inputFileName, "-ss", time.toString(), "-vframes", "1", "output.jpg"];
        await ffmpeg.exec(args);

        const imageData = ffmpeg.readFile("output.jpg");
        const fileData = await imageData;
        const blob = new Blob([fileData], { type: "image/jpeg" }) as Blob;
        const imageUrl = URL.createObjectURL(blob);
        await setFramePoints((prevFrames) => [...prevFrames, imageUrl]);

      }
    } catch (error) {
      console.error('Erreur lors de la récupération des frames :', error);
      return null;
    }
  };

  const handleChangeSlider = (event: Event, newValue: number | number[], activeThumb: number,) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      if(time[1] - newValue[0] >= 11) {
        setTime([Math.min(newValue[0], time[1] - minDistance), time[1] - 1]);
      } else {
        setTime([Math.min(newValue[0], time[1] - minDistance), time[1]]);
      }
    } else {
      if(newValue[1] - time[0] >= 11) {
        setTime([time[0] + 1, Math.max(newValue[1], time[0] + minDistance)]);
      } else {
        setTime([time[0], Math.max(newValue[1], time[0] + minDistance)]);
      }
    }

    setStart(formatSecondeTime(time[0]));
    setEnd(formatSecondeTime(time[1]));
  };

  const valuetext = (value: number) => {
    return formatSecondeTime(value);
  }

  return (
    <div className="ConfigPhoto overflowAuto gap10">
      <div className="textAlignCenter textColorSecondary fontWeight600 p40">{t('depositVideo')}</div>
      <DragNDrop defaultText={t('drapDrop')} onDropFile={handleClick} handleFile={handleFileChange} accept="video/*" />
      {videoLoading && (
        <div className="timeLine">
          {framePoints.map((frame, index) => (
            <img key={index} src={frame} alt={`Frame ${index + 1}`} style={{ width: `calc(100% / ${framePoints.length})` }}/>
          ))}
          <Slider
            getAriaLabel={() => '0:00'}
            value={time}
            onChange={handleChangeSlider}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
            marks={marks}
            disableSwap
            min={0}
            max={duration}
          />
        </div>
      )}

      {(selectedFile && fileType === 'video/mp4') ?
        <div className="pT50">
          <Button onClick={cutVideo} text="Modifier" color="white" background="#BCC0FF" backgroundHover="#5C72D8"/>
        </div>
        : ""
      }

      {modifyVideo ? (
        <div className="displayImage">
          <div className="textAlignCenter textColorSecondary fontWeight600 p40">{t('cutVideo')}</div>
          <video width="100%" height="100%" controls>
            <source src={fileModify} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : activeCutVideo && (
        <div className="m32"><span className="loader"></span></div>
        )
      }
    </div>
  );

}

export default ConfigPhoto;