import React from 'react';
import './Team.scss'
import {useTranslation} from "react-i18next";
import Footer from "../../components/footer/Footer";
import CardTeam from "../../components_global/card/CardTeam";
import profil1 from '../../assets/images/team/profil-photo1.jpg';
import profil2 from '../../assets/images/team/profil-photo-2.png';
import profil3 from '../../assets/images/team/profil-photo-3.jpg';
import profil4 from '../../assets/images/team/profil-photo-4.png';
import profil5 from '../../assets/images/team/profil-photo-5.jpg';
import profil6 from '../../assets/images/team/profil-photo-6.jpg';
import profil7 from '../../assets/images/team/profil-photo-7.jpg';
import profil8 from '../../assets/images/team/profil-photo-8.jpg';
import profil9 from '../../assets/images/team/profil-photo-9.jpeg';

const Team = () => {
  const {t} = useTranslation();
  const listItemCard = [
    {
      name: "Théo Miquet",
      role: "CTO",
      picture: profil6
    },
    {
      name: "Théo Nol-ygonnin",
      role: "CEO",
      picture: profil1
    },
    {
      name: "Chloé Gueret",
      role: t("fdevFront"),
      picture: profil2
    },
    {
      name: "Gaël Laxenaire",
      role: t("mdevFront"),
      picture: profil8
    },
    {
      name: "Houssam El affas",
      role: t("devBack"),
      picture: profil3
    },
    {
      name: "Nicolas Houillon",
      role: t("devBack"),
      picture: profil5
    },
    {
      name: "Edouard Paillard",
      role: "Devops",
      picture: profil4
    },
    {
      name: "Jason Seltensperger",
      role: "Product owner",
      picture: profil7
    },
    {
      name: "El Houcine Drissi",
      role: t('devUnity'),
      picture: profil9
    },
  ];
  return(
    <div className="timeline zindex0 relative flexSpaceBetween flexVertical">
      <div className="bgBlur absolute"/>
      <div className="textAlignCenter fontSize30 mV50">{t("ourTeam")}</div>
      <div className="flexHorizontal gap40 wrap mB50">
        {listItemCard.map((item, idItem) => (
          <CardTeam key={idItem} name={item.name} role={item.role} picture={item.picture}/>
        ))}
      </div>
      <Footer/>
    </div>
  );
}

export default Team;