import React, {FC, useEffect, useState} from 'react';
import Love from '../../assets/icons/Love';
import './Card.scss';
import {createFavorite} from "../../services/favoriteAPI";
import {useTranslation} from "react-i18next";
import {useToast} from "../../context/ToastContext";
import { Objet3D } from '../../type/type';

interface CardProps {
  data: Objet3D;
  isLoveHidden?: boolean;
  onCardClick?: (id: string) => void;
}

const Card: FC<CardProps> = ({data, isLoveHidden = false, onCardClick}) => {
  const {t} = useTranslation();
  const { showToast } = useToast();
  const [isLoved, setIsLoved] = useState(false);
  const imageSrc = `data:${data.preview.type};base64,${data.preview.data}`;
  const [isClicked, setIsClicked] = useState(false);

  const handleLoveClick = () => {
    setIsLoved(!isLoved);
  };

  const handleCardClick = () => {
    setIsClicked(!isClicked);

    if(data && data.id && onCardClick) {
      onCardClick(data.id);
    }
  }

  return (
    <div className={`card relative`} onClick={handleCardClick}>
      {isLoveHidden && (
        <div onClick={handleLoveClick}>
          <Love size={25} color={isLoved ? '#6419B9' : '#423F47'} className='absolute love'/>
        </div>
      )}
      <img className={`photo ${isClicked ? 'cardBorder' : ''}`} src={imageSrc} alt={data.firstnameCreator}/>
      <div className="flexVertical gap5">
        <div className="fontSize18 mT10">{data.firstnameCreator} {data.lastnameCreator}</div>
        <div className="fontSize14">{data.title}</div>
      </div>
    </div>
  );
}

export default Card;